import { createApi } from "@reduxjs/toolkit/query/react";
import { SearchResponse } from "@algolia/client-search";
import { BaseAlgoliaRequestOptions, buildUnlocalizedIndexName, client, Indices } from "../../algolia/services/search";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import { algoliaBaseQuery } from "../../algolia/services/baseApi";

type LatLngTuple = [string, string];

/**
 * This does not match platform languages as Romansh is not supported.
 */
export enum SwissNationalLanguage {
  German = "de",
  French = "fr",
  Italian = "it",
  Romansh = "rm",
}

export type GeocodingEntryResponseItem = {
  objectID: string;
  name: string;
  zip: { code: string; lat: number; lng: number }[];
  _geoloc: {
    lat: number;
    lng: number;
  };
  alternativeNames: {
    name: string;
    nameLanguage: SwissNationalLanguage;
  }[];
};

export function searchGeocodingIndex(
  query = "",
  requestOptions: BaseAlgoliaRequestOptions,
): Promise<SearchResponse<VehicleSearchResponse>> {
  return client.initIndex(buildUnlocalizedIndexName(Indices.geocoding)).search(query, requestOptions);
}

const GEO_SEARCH_RADIUS_M = 30_000;

export const geocodingApi = createApi({
  reducerPath: "geocodingApi",
  baseQuery: algoliaBaseQuery(searchGeocodingIndex),
  endpoints: builder => ({
    searchPlaces: builder.query<SearchResponse<GeocodingEntryResponseItem>, string>({
      query: (query: string) => ({
        options: {
          query,
          hitsPerPage: 40,
          attributesToHighlight: ["name", "alternativeNames", "zip"],
        },
      }),
    }),
    findPlace: builder.query<SearchResponse<GeocodingEntryResponseItem>, LatLngTuple>({
      query: ([lat, lng]) => ({
        options: {
          aroundLatLng: `${lat}, ${lng}`,
          aroundRadius: GEO_SEARCH_RADIUS_M,
          hitsPerPage: 1,
        },
      }),
    }),
  }),
});

export const { useSearchPlacesQuery, useFindPlaceQuery } = geocodingApi;
